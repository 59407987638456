import { useState, useEffect } from "react";
import { CompHead } from "./head";
import { CompPerfilUsuario } from "../gestion_usuarios/perfil";
import { CompEmpresa } from "../gestion_usuarios/empresa";
import { ElHome } from "../home/home";
import { NotFound404 } from "./404_not_found";
import { CompPanelReportes } from "../reportes/panelReportes";
import { CompPanelCompensar } from "../reportes/panelCompensar";
import { CompFaq } from "../home/faq";
import { CompCalculadoras } from "../calculadoras/calculadoras";
import { CompMitigacion } from "../home/medidasMitigadoras";
import { CompMitigacionPersonal } from "../home/medidasMitigadorasPersonal";
import { CompNuevaEmpresa } from "../gestion_usuarios/nuevaEmpresa";
import { CompPanelEmpresa } from "../gestion_usuarios/panelEmpresa";
import { CompPanelCalculadoras } from "../calculadoras/panelCalculadoras";
import { CompPassUsuario } from "../gestion_usuarios/contrasenia";
import { CompPanelCompensador } from "../compensadoras/panelCompensador";
import { CompTermAndCondiciones } from "../home/terminosycondiciones";
import { BtnFlotante } from "../navegacion/BtnFlotante";
import { Footer } from "../navegacion/Footer";
import { PagoInternoCheck } from "../pagos/pagoInternoCheck";
import { Routes, Route, useLocation } from "react-router-dom";
import { LoadMePlease } from "./pantalla_carga";
import { useSesion } from "./sesionContexto";
import { useTranslation } from "react-i18next";
export const CompGeneral = () => {
  const [t] = useTranslation("global");
  const { sesion } = useSesion();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleStart = () => setIsLoading(true);
    const handleComplete = () => setIsLoading(false);

    handleStart();
    const timeout = setTimeout(handleComplete, 500); // Simula un tiempo de carga

    return () => clearTimeout(timeout);
  }, [location.pathname]);

  return (
    <div id="body_calculadora" className="body_calculadora">
      <CompHead sesion={sesion} />
          {isLoading ? (
            <LoadMePlease cargando={t("mensajes.mensaje69")} />
          ) : (
            <Routes>
              <Route path="/" element={<ElHome sesion={sesion} />} />
              <Route path="*" element={<NotFound404 />} />

              {sesion.rol > 0 && (
                <>
                  <Route path="/reportes" element={<CompPanelReportes sesion={sesion} />} />
                  <Route path="/compensar" element={<CompPanelCompensar sesion={sesion} />} />
                  
                  <Route path="/perfil" element={<CompPerfilUsuario />} />
                  <Route path="/credenciales" element={<CompPassUsuario />} />
                  <Route path="/faq" element={<CompFaq />} />
                  <Route path="/terminos_y_condiciones" element={<CompTermAndCondiciones />} />

                  {sesion.rol === 1 && (
                    <Route
                      path="/medidasMitigadorasPersonal"
                      element={<CompMitigacionPersonal sesion={sesion} />}
                    />
                  )}

                  {sesion.rol === 2 && (
                    <Route
                      path="/medidasMitigadoras"
                      element={<CompMitigacion sesion={sesion} />}
                    />
                  )}

                  {sesion.rol > 0 && (
                    <>
                      <Route
                        path="/calculadoras/"
                        element={<CompPanelCalculadoras sesion={sesion} />}
                      />
                      <Route
                        path="/calculadoras/:codigoCalculadora"
                        element={<CompCalculadoras sesion={sesion} />}
                      />
                    </>
                  )}

                  {sesion.compensador && (
                    <Route path="/compensador" element={<CompPanelCompensador sesion={sesion} />} />
                  )}

                  {(sesion.rol !== 1 && sesion.rol !== 4 && sesion.rol !== 5) && (
                    <>
                      {sesion.rol === 3 && (
                        <>
                          <Route path="/empresa" element={<CompEmpresa sesion={sesion} />} />
                          <Route path="/empresa/nueva" element={<CompNuevaEmpresa />} />
                        </>
                      )}
                      {sesion.duenio && (
                        <>
                          <Route path="/empresa/:idEmpresa">
                            <Route path=":tabs" element={<CompPanelEmpresa sesion={sesion} />} />
                            <Route path="" element={<CompPanelEmpresa sesion={sesion} />} />
                          </Route>
                          <Route
                            path='/checkout/interno/:estado/:estado_pago/data'
                            element={<PagoInternoCheck sesion={sesion} />}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              
            </Routes>
            
          )}
      <BtnFlotante />
  
   
    </div>
    
  );
};
