import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import {
	PlusOutlined,
	RightOutlined,
	LeftOutlined,
	CloseOutlined,
	DeleteOutlined,
	EditOutlined,
} from "@ant-design/icons";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
// import { ChartEmisionesMulti } from "../charts/ChartEmisionesMulti";
import { InputPrecio } from "../componentes/inputComponente";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { FaQuestionCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import {
	traerDatos,
	HeadersAuth,
	CheckExpiracion,
	traerDatosObjeto,
	fNum,
	cambioValor,
	sacaComas,
} from "../funciones_utiles";
import {
	validarNumericoDecimal,
	validarFecha,
	validarSelector,
} from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { Tooltip, Tour } from "antd";

const MySwal = withReactContent(Swal);

// const MemoizedChartEmisionesMulti = React.memo(ChartEmisionesMulti);
//DESCARGAR MOMENT JS
export const CompCalculadoraEstadiaHotel = ({ sesion, titulo }) => {
	const [t, i18n] = useTranslation("global");
	const currentLanguage = i18n.language;

	const URI_PAISES =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/paises_estadia";

	// console.log(empresasUsuario)
	// console.log(empresasUsuario.some(checkEmpresa))

	const refCheckin = useRef(null);
	const refCheckout = useRef(null);
	const refPais = useRef(null);
	const refQRooms = useRef(null);	
	const refBtnGuardar = useRef(null);
	const refTabla = useRef(null);

	const [abrirAyuda, setAbrirAyuda] = useState(false);

	const propsNext = {
		children: (
			<RightOutlined style={{ color: "white", marginBottom: "20px" }} />
		),
		style: {
			height: 31,
		},
	};
	const propsFin = {
		children: (
			<CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
		),
		style: {
			height: 31,
		},
	};
	const propsPrev = {
		children: <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />,
		style: {
			height: 31,
		},
	};
	const pasos = [
		{
			title: t("calculadoras.pasos.titulo30"),
			description: t("calculadoras.pasos.subtitulo30"),
			target: () => refCheckin.current,
			prevButtonProps: propsPrev,
			nextButtonProps: propsNext,
		},
		{
			title: t("calculadoras.pasos.titulo31"),
			description: t("calculadoras.pasos.subtitulo31"),
			target: () => refCheckout.current,
			prevButtonProps: propsPrev,
			nextButtonProps: propsNext,
		},
		{
			title: t("calculadoras.pasos.titulo32"),
			description: t("calculadoras.pasos.subtitulo32"),
			target: () => refPais.current,
			prevButtonProps: propsPrev,
			nextButtonProps: propsNext,
		},
		{
			title: t("calculadoras.pasos.titulo33"),
			description: t("calculadoras.pasos.subtitulo33"),
			target: () => refQRooms.current,
			prevButtonProps: propsPrev,
			nextButtonProps: propsNext,
		},

		{
			title: t("calculadoras.pasos.titulo4"),
			description: t("calculadoras.pasos.subtitulo4"),
			target: () => refBtnGuardar.current,
			prevButtonProps: propsPrev,
			nextButtonProps: propsNext,
		},
		{
			title: t("calculadoras.pasos.titulo6"),
			description: t("calculadoras.pasos.subtitulo6"),
			target: () => refTabla.current,
			prevButtonProps: propsPrev,
			nextButtonProps: propsFin,
		},
	];

	// const anioActual = new Date().getFullYear();
	// const [yearRange, setYearRange] = useState(anioActual);
	// const [sufijo, setSufijo] = useState("S/N");

	const [datosTable, setDatosTable] = useState([]);

	const [opcionesPaises, setOpcionesPaises] = useState([]);

	const [idUnico, setIdUnico] = useState("");
	const [tempDatosCalculo, setTempDatosCalculo] = useState({});
	const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
	const [idSede, setIdSede] = useState(sesion.sedeActiva);
	const [fuenteEmision, setFuenteEmision] = useState("");

	const handleQuestionIconClick = () => {
		
			Swal.fire({
				imageUrl: "img/imagen1.png",
				imageHeight: 65,
				text: "www.hotelfootprints.org",
			});
		
	};

	useEffect(() => {
		setIdEmpresa(sesion.empresaActiva);
		setIdSede(sesion.sedeActiva);
		if (idUnico) {
			(async () => {
				const URI_CONSUMOS_COMBUSTION_UNICOS =
					process.env.REACT_APP_DOMINIO +
					process.env.REACT_APP_PUERTO +
					"/datos_consumo_unico?type=hotel_stay&unique_id=" +
					idUnico +
					"&ids=" +
					idSede +
					"&id=" +
					idEmpresa;
				const datosConsumo = await traerDatosObjeto(
					URI_CONSUMOS_COMBUSTION_UNICOS
				);
				// Aplicar formato a los Kwhs consumidos

				setTempDatosCalculo(datosConsumo);
			})();
		} else {
			setTempDatosCalculo({
				checkin: "",
				checkout: "",
				country: "",
				quantity_rooms: "",
			});
		}
	}, [idUnico, idSede, idEmpresa, sesion.empresaActiva, sesion.sedeActiva]);
	useEffect(() => {
		(async () => {
			setOpcionesPaises(await traerDatos(URI_PAISES));
		})();
	}, []);
	const [show, setShow] = useState("");
	const [fetchDatos, setFetchDatos] = useState([]);

	var [tooltipVisible, setTooltipVisible] = useState({
		checkin: false,
		checkout: false,
		country: false,
		quantity_rooms: false,
	});
	const [errores, setErrores] = useState({
		checkin: "",
		checkout: "",
		country: "",
		quantity_rooms: "",
	});
	const campos = [
		{ name: "checkin", validator: validarFecha },
		{ name: "checkout", validator: validarFecha },
		{ name: "country", validator: validarSelector },
		{ name: "quantity_rooms", validator: validarSelector },
	];
	useEffect(() => {
		const actualizarChart = async () => {
			setDatosTable(fetchDatos);
		};
		actualizarChart();
	}, [fetchDatos, show]);

	useEffect(() => {
		(async () => {
			setFetchDatos(await traerDatos(URI_CONSUMOS_ENERGIA));
		})();
	}, [show]);

	let URI_NUEVO_CALCULO;
	const URI_CONSUMOS_ENERGIA =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/datos_consumo?type=estadia_hotel&id=" +
		idEmpresa +
		"&ids=" +
		idSede;
	const elScroll = { x: 0 };
	const Columns = [
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span style={{ fontSize: "0.8em" }}>Check-In</span>
				</div>
			),
			key: "fecha",
			align: "center",
			sorter: (a, b) => a.fecha.localeCompare(b.fecha),
			render: (a) => (
				<div style={{ fontSize: "12px", padding: "2px 4px" }}>
					{moment(`${a.fecha}`, "YYYY-MM-DD").format("DD/MM/YYYY")}
				</div>
			),
		},
        {
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span style={{ fontSize: "0.8em" }}>Check-Out</span>
				</div>
			),
			key: "fecha_hasta",
			align: "center",
			sorter: (a, b) => a.fecha_hasta.localeCompare(b.fecha_hasta),
			render: (a) => (
				<div style={{ fontSize: "12px", padding: "2px 4px" }}>
					{moment(`${a.fecha_hasta}`, "YYYY-MM-DD").format("DD/MM/YYYY")}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span style={{ fontSize: "0.8em" }}> {t("tablas.dias")}</span>
				</div>
			),
			align: "right",
			sorter: (a, b) => a.cantidad_dias - b.cantidad_dias,
			render: (a) => (
				<div style={{ fontSize: "12px", padding: "2px 4px" }}>
					{parseInt(a.cantidad_dias)} 
				</div>
			),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span style={{ fontSize: "0.8em" }}>{t("tablas.habitaciones")}</span>
				</div>
			),
			align: "right",
			sorter: (a, b) => -a.cantidad_habitaciones.localeCompare(b.cantidad_habitaciones),
			render: (a) => (
				<div style={{ fontSize: "12px", padding: "2px 4px" }}>
					{parseInt(a.cantidad_habitaciones)} 
				</div>
			),
		},
        {
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span style={{ fontSize: "0.8em" }}>{t("tablas.pais")}</span>
				</div>
			),
			align: "left",
			sorter: (a, b) => -a.pais.localeCompare(b.pais),
			render: (a) => (
				<div style={{ fontSize: "12px", padding: "2px 4px" }}>
					{currentLanguage === "es" ? a.nombre_pais : a.nombre_pais_en}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span
						style={{ fontSize: "0.8em" }}
						dangerouslySetInnerHTML={{ __html: t("tablas.toneladas") }}
					/>
				</div>
			),
			key: "kgcodose_emitido",
			align: "right",
			sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
			render: (a) => (
				<div style={{ fontSize: "12px", padding: "2px 4px" }}>
					{fNum(a.kgcodose_emitido)}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span style={{ fontSize: "0.8em" }}>+ Info</span>
				</div>
			),
			key: "id",
			align: "center",
			render: (dato) => (
				<div className="row">
					<div className="col align-items-center">
						{dato.compensado === 1 && (
							<>
								<button
									className="boton-vehiculos-nuevo justify-content-center"
									onClick={(e) => borrar(e, dato.id)}>
									<DeleteOutlined />
								</button>

								<button
									className="boton-vehiculos-nuevo justify-content-center"
									onClick={(e) => traerCalculo(e, dato.id)}>
									<EditOutlined />
								</button>
							</>
						)}
						{dato.compensado === 2 && (
							<span>
								<b> {t("compensacion.estados.pendiente")}</b>
							</span>
						)}
						{dato.compensado === 3 && (
							<span>
								<b>{t("compensacion.estados.compensado")}</b>
							</span>
						)}
					</div>
				</div>
			),
		},
	];

	const handleChange = async (e) => {
		const { id, value } = e.target;
		// console.log("ES : " + value);

		setTempDatosCalculo({
			...tempDatosCalculo,
			[id]: value,
		});

		let erroresTemp = { ...errores };
		const validarCampo = async (campo, valor) => {
			const resultadoValidacion = await campo(valor, t);
			return {
				mensaje: resultadoValidacion.mensaje,
				valido: resultadoValidacion.valido,
			};
		};
		const campoActual = campos.find((campo) => campo.name === id);

		if (campoActual) {
			const mira = await validarCampo(campoActual.validator, value);
			erroresTemp[id] = mira.mensaje;
			setTooltipVisible((prevState) => ({
				...prevState,
				[id]: !mira.valido,
			}));
			setTimeout(() => {
				setTooltipVisible((prevState) => ({
					...prevState,
					[id]: false,
				}));
			}, 3000);
		}

		setErrores(erroresTemp);
	};

	const guardar = async (e) => {
		e.preventDefault();
		let hayErrores = false;
		const erroresTemp = {};
		for (const campo of campos) {
			const valorCampo = String(tempDatosCalculo[campo.name]);
			const resultadoValidacion = await campo.validator(
				valorCampo,
				tooltipVisible
			);

			if (resultadoValidacion.valido === false) {
				hayErrores = true;
				erroresTemp[campo.name] = resultadoValidacion.mensaje;
				setTooltipVisible((prevState) => ({
					...prevState,
					[campo.name]: !resultadoValidacion.valido,
				}));
				setTimeout(() => {
					setTooltipVisible((prevState) => ({
						...prevState,
						[campo.name]: false,
					}));
				}, 3000);
			}
		}
		if (hayErrores) {
			setErrores(erroresTemp);
			return;
		} else {
			if (idUnico) {
				URI_NUEVO_CALCULO =
					process.env.REACT_APP_DOMINIO +
					process.env.REACT_APP_PUERTO +
					"/calculo_estadia?id=" +
					idEmpresa +
					"&ids=" +
					idSede +
					"&unique_id=" +
					idUnico;
			} else {
				URI_NUEVO_CALCULO =
					process.env.REACT_APP_DOMINIO +
					process.env.REACT_APP_PUERTO +
					"/calculo_estadia?id=" +
					idEmpresa +
					"&ids=" +
					idSede +
					"&unique_id=none";
			}

			const registro = await axios.post(
				URI_NUEVO_CALCULO,
				tempDatosCalculo,
				HeadersAuth
			);

			if (registro.data.errors) {
				const errores = registro.data.errors;
				// console.log(errores)
				const msgError = errores.map((error) => {
					return (
						"<pre>" +
						(currentLanguage === "es" ? error.msg.ES : error.msg.EN) +
						"</pre>"
					);
				});
				MySwal.fire({
					html: `${msgError}`,
					imageUrl: "img/lechuza_triste.png",
					imageHeight: 65,
					confirmButtonText: "Ok",
				});
			} else {
				if (registro.data.code === 409) {
					CheckExpiracion();
				} else {
					if (registro.data.code === 200) {
						MySwal.fire({
							html: (
								<PresetResultado resultado={fNum(registro.data.result)} t={t} />
							),
							imageUrl: "img/mensajes/resultado.svg",
							imageHeight: 65,
							confirmButtonText: "Ok",
						});
						setTempDatosCalculo({
							checkin: "",
							checkout: "",
							country: "",
							quantity_rooms: "",
						});
						if (show === "") {
							setShow("guardado");
						} else {
							setShow("");
						}
						setIdUnico("");
					}
				}
			}
		}
	};
	const traerCalculo = async (e, value) => {
		e.preventDefault();
		window.scrollTo(0, 0);
		setIdUnico(value);
	};
	const borrar = async (e, value) => {
		e.preventDefault();
		window.scrollTo(0, 0);
		//const { value } = e.target;
		// console.log(value)
		const URI_BORRAR_CONSUMO =
			process.env.REACT_APP_DOMINIO +
			process.env.REACT_APP_PUERTO +
			"/borrar_consumo?type=hotel_stay&id=" +
			idEmpresa +
			"&ids=" +
			idSede +
			"&unique_id=" +
			value;
		const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
		if (borrar.data.code === 401 || borrar.data.code === 402) {
			MySwal.fire({
				text: t("mensajes.mensaje12"),
				imageUrl: "img/lechuza-triste.png",
				imageHeight: 55,
				confirmButtonText: "Ok",
			});
		} else {
			if (borrar.data.code === 409) {
				CheckExpiracion();
			} else {
				if (borrar.data.code === 200) {
					MySwal.fire({
						text: t("mensajes.mensaje10"),
						imageUrl: "img/borrar.png",
						imageHeight: 50,
						confirmButtonText: "Ok",
					});
					if (show === "") {
						setShow("borrado");
					} else {
						setShow("");
					}
					setIdUnico("");
				}
			}
		}
	};

	return (
		<>
			<br></br>
			<div className="perfil row g-3 justify-content-center ms-4 me-4">
				<div className="row row-form px-2">
					<div className="col-12 col-md-5">
						<div className="contenedor-form-materiales">
							<div className="contenedor-titulo w-100">
								<h2 className="titulo-calculadoras">
									{titulo}
									<Tooltip title={t("tooltip.ayuda")} placement="top">
										<FaQuestionCircle
											style={{
												marginLeft: "5px",
												cursor: "pointer",
												color: "red",
											}}
											onClick={() => setAbrirAyuda(true)}
										/>
									</Tooltip>
									{idUnico ? (
										<>
											<Tooltip title={t("tooltip.nuevoCalculo")}>
												<PlusOutlined
													className="btnnuevocalculo p-1 mb-1"
													onClick={(e) => setIdUnico("")}
												/>
											</Tooltip>{" "}
										</>
									) : (
										<></>
									)}
								</h2>
							</div>
							<form onSubmit={guardar}>
								<div className="row pb-2 mb-3 justify-content-center custom-select-row">
									<div
										ref={refCheckin}
										className="col-md-6 col-12 d-flex flex-column align-items-center">
										<div className="d-flex align-items-center w-100 justify-content-start">
											<label
												className="select_lista text-left"
												style={{ flex: "1 0 auto" }}>
												{t("tablas.checkin")}
											</label>{" "}
										</div>
										<Tooltip
											title={
												!!errores.checkin && <span>{errores.checkin}</span>
											}
											open={tooltipVisible.checkin}
											color="#1CA6AF">
											<div className="w-100">
												<input
													className="form-control w-100"
													type="date"
													min={"2010-01-01"}
													max={`${new Date().getFullYear()}-${(
														"0" +
														(new Date().getMonth() + 1)
													).slice(-2)}-${("0" + new Date().getDate()).slice(
														-2
													)}`}
													id="checkin"
													value={tempDatosCalculo.checkin || ""}
													onChange={handleChange}
												/>{" "}
											</div>
										</Tooltip>
									</div>
									<div
										ref={refCheckout}
										className="col-md-6 col-12 d-flex flex-column align-items-center">
										<div className="d-flex align-items-center w-100 justify-content-start">
											<label
												className="select_lista text-left"
												style={{ flex: "1 0 auto" }}>
												{t("tablas.checkout")}
											</label>{" "}
										</div>
										<Tooltip
											title={
												!!errores.checkout && <span>{errores.checkout}</span>
											}
											open={tooltipVisible.checkout}
											color="#1CA6AF">
											<div className="w-100">
												<input
													className="form-control w-100"
													type="date"
													min={tempDatosCalculo.checkin || "2010-01-01"}
													max={`${new Date().getFullYear()}-${(
														"0" +
														(new Date().getMonth() + 1)
													).slice(-2)}-${("0" + new Date().getDate()).slice(
														-2
													)}`}
													id="checkout"
													value={tempDatosCalculo.checkout || ""}
													onChange={handleChange}
												/>{" "}
											</div>
										</Tooltip>
									</div>
									<div
										ref={refPais}
										className="col-md-6 col-12 d-flex flex-column align-items-center">
										<div className="d-flex align-items-center w-100 justify-content-start">
											<label
												className="select_lista text-left"
												style={{ flex: "1 0 auto" }}>
												{" "}
												{t("tablas.pais")}
											</label>{" "}
										</div>
										<Tooltip
											title={
												!!errores.country && <span>{errores.country}</span>
											}
											open={tooltipVisible.country}
											color="#1CA6AF">
											<select
												className="form-select form-select-sm w-100"
												id="country"
												onChange={handleChange}
												value={tempDatosCalculo.country || ""}>
												<option value="">{t("registro.pais")}</option>
												{opcionesPaises.map((option) => (
													<option key={option.id} value={option.id}>
														{option.paisnombre}
													</option>
												))}
											</select>
										</Tooltip>
									</div>
									<div
										ref={refQRooms}
										className="col-md-6 col-12 d-flex flex-column align-items-center">
										<div className="d-flex align-items-center w-100 justify-content-start">
											<label className="select_lista text-left">
                                            {" "}
                                            {t("tablas.habitaciones")}
												<Tooltip
													title={t("tooltip.fuenteEmision")}
													placement="top">
													<FaQuestionCircle
														style={{
															marginLeft: "5px",
															cursor: "pointer",
															color: "#1ca5af",
														}}
														onClick={() => handleQuestionIconClick()}
													/>
												</Tooltip>
											</label>
										</div>
										<Tooltip
											title={
												!!errores.quantity_rooms && <span>{errores.quantity_rooms}</span>
											}
											open={tooltipVisible.quantity_rooms}
											color="#1CA6AF">
											<select
												className="form-select form-select-sm w-100"
												id="quantity_rooms"
												onChange={handleChange}
												value={tempDatosCalculo.quantity_rooms || ""}>
												<option key="" value="">
													{t("empresa.datosSede.elejirOpcion")}
												</option>
												{Array.from({ length: 20 }, (_, i) => i + 1).map(
													(number) => (
														<option key={number} value={number}>
															{number}
														</option>
													)
												)}
											</select>
										</Tooltip>
									</div>

									<div
										ref={refBtnGuardar}
										className="col-md-6 col-12 mt-4 d-flex justify-content-end w-100">
										<BtnPrincipal name={t("botones.calcular")} />
									</div>
								</div>
							</form>
						</div>
					</div>

					<div className="col-12 col-md-7">
						<div className="contenedor-tabla-combustion">
							<div ref={refTabla}>
								<h2 className="texto-explicacion"> {t("tablas.info4")}</h2>
								<TablaCompleta
									fetchDatos={datosTable}
									Columns={Columns}
									elScroll={elScroll}
									sizeVariant="small"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Tour
				open={abrirAyuda}
				onClose={() => setAbrirAyuda(false)}
				steps={pasos}
			/>
		</>
	);
};
