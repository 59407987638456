import { Avatar, List, Input, Tooltip } from "antd";
import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
	traerDatos,
	HeadersAuth,
	traerDatosObjeto,
	CheckExpiracion,
} from "../../funciones_utiles";
import {
	validarTelefono,
	validarTextoNumerico,
	validarSelector,
} from "../../validaciones";
import { RiSeedlingLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_PAISES =
	process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/paises";
const URI_PROVINCIAS =
	process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/provincias";

export const CompDatosSedes = ({ sesion, id_empresa, uri_sxe }) => {
	const plan = sesion.datosPlanes.find(
		(item) => parseInt(item.id_empresa) === parseInt(id_empresa)
	);
	const [t, i18n] = useTranslation("global");
	const currentLanguage = i18n.language;
	const [opcionesPaises, setOpcionesPaises] = useState([]);
	const [opcionesProvincias, setOpcionesProvincias] = useState([]);

	const [tempDatosSede, setTempDatosSede] = useState({});
	const [idSede, setIdSede] = useState("");
	const [infoSedes, setInfoSedes] = useState([]);
	const URI_GUARDAR_SEDE =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/nueva_sede?id=" +
		id_empresa;
	const URI_ACTUALIZAR_SEDE =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/update_sede?id=" +
		id_empresa +
		"&ids=" +
		idSede;

	const [inputValue, setInputValue] = useState("");
	const [filteredList, setFilteredList] = useState();
	// console.log(URI_VEHICULOS_EMPRESA_UNICO)
	const [show, setShow] = useState("");

	useMemo(() => {
		(async () => {
			setOpcionesPaises(await traerDatos(URI_PAISES));
		})();
	}, []);
	useEffect(() => {
		(async () => {
			setOpcionesProvincias(
				await traerDatos(URI_PROVINCIAS + "?id=" + tempDatosSede.pais)
			);
		})();
	}, [tempDatosSede.pais]);
	useEffect(() => {
		(async () => {
			setInfoSedes(await traerDatos(uri_sxe));
		})();
		if (show === "editar" || show === "nuevo") {
			if (show === "nuevo") {
				setTempDatosSede({
          nombre: "",
          pais: "",
          provincia_estado: "",
          ciudad: "",
          domicilio: "",
          telefono: "",
        });
			} else if (show === "editar" && idSede !== "") {
				(async () => {
					const URI_SEDE_UNICO =
						process.env.REACT_APP_DOMINIO +
						process.env.REACT_APP_PUERTO +
						"/datos_sede?id=" +
						id_empresa +
						"&ids=" +
						idSede;
					setTempDatosSede(await traerDatosObjeto(URI_SEDE_UNICO));
					// console.log(URI_SEDE_UNICO)
				})();
			}
		} else {
		}
	}, [show, id_empresa, idSede, uri_sxe]);
	var [tooltipVisible, setTooltipVisible] = useState({
		nombre: false,
		pais: false,
		provincia_estado: false,
		ciudad: false,
		domicilio: false,
		telefono: false,
	});
	const [errores, setErrores] = useState({
		nombre: "",
		pais: "",
		provincia_estado: "",
		ciudad: "",
		domicilio: "",
		telefono: "",
	});
	const campos = [
		{ name: "nombre", validator: validarTextoNumerico },
		{ name: "pais", validator: validarSelector },
		{ name: "provincia_estado", validator: validarSelector },
		{ name: "ciudad", validator: validarTextoNumerico },
		{ name: "domicilio", validator: validarTextoNumerico },
		{ name: "telefono", validator: validarTelefono },
	];
	//Buscador
	useEffect(() => {
		// setFilteredList(infoVehiculos)
		if (inputValue) {
			setFilteredList(
				filteredList.filter(
					(item) =>
						item.nombre
							.toString()
							.toLowerCase()
							.indexOf(inputValue.toString().toLowerCase()) > -1
				)
			);
		} else {
			setFilteredList(infoSedes);
		}
	}, [inputValue, infoSedes, filteredList]);

	function onSearch(e) {
		setInputValue(e.target.value);
	}

	const cambiarShow = async (id = "", value = "") => {
		window.scrollTo(0, 0);
		setShow("");
		setShow(value);

		if (id) {
			let idSplit = id.split("-");
			if (value === "editar") {
				setIdSede(idSplit[0]);
			}
		}
	};

	const handleChange = async (e) => {
		const { id, value } = e.target;
		// setTempDatosSede({
		//     ...tempDatosSede,
		//     [id]: value
		// })
		if (id === "pais") {
			setTempDatosSede({
				...tempDatosSede,
				provincia_estado: "",
				[id]: value,
			});
		} else {
			setTempDatosSede({
				...tempDatosSede,
				[id]: value,
			});
		}
		let erroresTemp = { ...errores };
		const validarCampo = async (campo, valor) => {
			const resultadoValidacion = await campo(valor, t);
			return {
				mensaje: resultadoValidacion.mensaje,
				valido: resultadoValidacion.valido,
			};
		};

		const campoActual = campos.find((campo) => campo.name === id);

		if (campoActual) {
      console.log(campoActual)
			const mira = await validarCampo(campoActual.validator, value);
			erroresTemp[id] = mira.mensaje;
			setTooltipVisible((prevState) => ({
				...prevState,
				[id]: !mira.valido,
			}));
			setTimeout(() => {
				setTooltipVisible((prevState) => ({
					...prevState,
					[id]: false,
				}));
			}, 3000);
		}

		setErrores(erroresTemp);
	};
	const guardar = async (e) => {
		e.preventDefault();
		let hayErrores = false;
		const erroresTemp = {};
		for (const campo of campos) {
      console.log(campo.name)
      const valorCampo = String(tempDatosSede[campo.name]);
      console.log(valorCampo)
      const resultadoValidacion = await campo.validator(valorCampo, t);
      console.log(resultadoValidacion)
      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

		if (hayErrores) {
			console.log(erroresTemp);
			setErrores(erroresTemp);
			return;
		} else {
			let registro;
			if (show === "nuevo") {
				registro = await axios.post(
					URI_GUARDAR_SEDE,
					tempDatosSede,
					HeadersAuth
				);
			} else if (show === "editar" && idSede !== "") {
				registro = await axios.post(
					URI_ACTUALIZAR_SEDE,
					tempDatosSede,
					HeadersAuth
				);
			}
			if (registro.data.errors) {
				const errores = registro.data.errors;

				const msgError = errores.map((error) => {
					return (
						"<pre>" +
						(currentLanguage === "es" ? error.msg.ES : error.msg.EN) +
						"</pre>"
					);
				});
				MySwal.fire({
					html: `${msgError}`,
					icon: "warning",
					confirmButtonText: "Ok",
				});
			} else {
				if (registro.data.code === 409) {
					CheckExpiracion();
				} else {
					if (registro.data.code === 200) {
						MySwal.fire({
							text: t("mensajes.mensaje8"),
							imageUrl: "img/nueva-empresa.png",
							imageHeight: 75,
							confirmButtonText: "Ok",
						  }).then(() => {
							window.location.reload(); // Refresca la página después de que el usuario hace clic en "Ok"
						  });
						setShow("");
					}
				}
			}
		}
	};
	const borrar = async (e) => {
		e.preventDefault();

		let validarBorrar = MySwal.fire({
			title: t("mensajes.mensaje52"),
			text: t("mensajes.mensaje53"),
			imageUrl: "img/borrar.png",
			imageHeight: 65,
			showCancelButton: true,
			confirmButtonColor: "#1CA6AF",
			cancelButtonColor: "#d33",
			confirmButtonText: t("botones.aceptar"),
			cancelButtonText: t("botones.cancelar"),
		});
		if ((await validarBorrar).isConfirmed) {
			console.log("hola");
			window.scrollTo(0, 0);
			const { id } = e.target;
			const URI_BORRAR_SEDE =
				process.env.REACT_APP_DOMINIO +
				process.env.REACT_APP_PUERTO +
				"/borrar_sede?id=" +
				id_empresa +
				"&ids=" +
				id;
			const borrar = await axios.delete(URI_BORRAR_SEDE, HeadersAuth);
			if (borrar.data.code === 401 || borrar.data.code === 402) {
				MySwal.fire({
					text: t("mensajes.mensaje12"),
					imageUrl: "img/lechuza-triste.png",
					imageHeight: 55,
					confirmButtonText: "Ok",
				});
			} else {
				if (borrar.data.code === 409) {
					CheckExpiracion();
				} else {
					if (borrar.data.code === 200) {
						MySwal.fire({
							text: t("mensajes.mensaje54"),
							imageUrl: "img/borrar.png",
							imageHeight: 50,
							confirmButtonText: "Ok",
						});
						if (show === "") {
							setShow("borrado");
						} else {
							setShow("");
						}
					} else if (borrar.data.code === 403) {
						MySwal.fire({
							text: t("mensajes.mensaje55"),
							imageUrl: "img/borrar.png",
							imageHeight: 50,
							confirmButtonText: "Ok",
						});
						if (show === "") {
							setShow("borrado");
						} else {
							setShow("");
						}
					}
				}
			}
		}
	};
	const limiteSedes = (e) => {
		e.preventDefault();
		Swal.fire({
			title: t("mensajes.mensaje56"),
			text: t("mensajes.mensaje57"),
			imageUrl: "img/lechuza-triste.png",
			imageHeight: 50,
			confirmButtonText: "Ok",
		});
	};
	return (
		<>
			<div
				className="row justify-content-center"
				style={{ marginBottom: "1em" }}>
				<div className="col-10">
					<div className="row mb-2">
						<div
							className="col col-sedes mb-2 text-start"
							style={{ textAlign: "start" }}>
							{infoSedes.length < parseInt(plan.max_sedes) ? (
								<button
									className="boton-agregar-empresa justify-content-start align-items-start text-start "
									value="nuevo"
									onClick={() => cambiarShow("", "nuevo")}>
									+
								</button>
							) : (
								<button
									className="boton-agregar-empresa "
									style={{ textAlign: "start" }}
									onClick={limiteSedes}>
									+
								</button>
							)}
						</div>

						<div className="col col-sedes" style={{ textAlign: "end" }}>
							<Input
								className="dominio"
								placeholder={t("registro.nombre")}
								onChange={(e) => onSearch(e)}
								style={{
									width: 200,
								}}
							/>
						</div>
					</div>
					<List
						itemLayout="horizontal"
						dataSource={filteredList}
						className="lista-sedes"
						pagination={{
							size: "small",
							pageSize: 5,
						}}
						size="small"
						renderItem={(item) => (
							<List.Item
								actions={[
									<Tooltip title={t("tooltip.editar")}>
										<button
											className="boton-vehiculos"
											onClick={() =>
												cambiarShow(item.id_sede + "-sedes", "editar")
											}
											id={item.id_sede + "-sedes"}>
											<EditOutlined />
										</button>
									</Tooltip>,
									<Tooltip title={t("tooltip.borrar")}>
										<button
											className="boton-vehiculos justify-content-end"
											onClick={borrar}
											id={item.id_sede}>
											<DeleteOutlined />
										</button>
									</Tooltip>,
								]}>
								<List.Item.Meta
									avatar={<Avatar src=".././img/empresa_nuevo.png" />}
									title={item.nombre}
								/>
							</List.Item>
						)}
					/>

					<div className="col  ">
						{/* <span>{console.log(infoVehiculos)}</span> */}
						{show === "editar" ? (
							<div className="contenedor">
								<h3 className="subtitulo-empresas">
									{" "}
									{t("empresa.datosSede.modificarSede")} <hr></hr>
								</h3>
							</div>
						) : show === "nuevo" ? (
							<div className="col">
								<h3 className="subtitulo-empresas">
									{t("botones.nuevaSede")}{" "}
								</h3>
							</div>
						) : (
							""
						)}

						{show === "editar" || show === "nuevo" ? (
							<form onSubmit={guardar}>
								<div className="row row-form px-2 justify-content-center">
									<div className="col-12 col-md-8 ">
										<div className="row pb-2 mb-3 justify-content-center custom-select-row">
											<div className="col-md-6 col-12 d-flex flex-column align-items-center">
												<div className="d-flex align-items-center w-100 justify-content-start">
													<label
														className="select_lista text-left"
														style={{ flex: "1 0 auto" }}>
														{t("empresa.datosSede.nombreSede")}{" "}
													</label>
												</div>
												<Tooltip
													title={
														!!errores.nombre && <span>{errores.nombre}</span>
													}
													open={tooltipVisible.nombre}
													color="#1CA6AF">
													<input
														className="form-control w-100"
														type="text"
														placeholder={t("tooltip.nombre")}
														id="nombre"
														value={tempDatosSede.nombre || ""}
														onChange={handleChange}
													/>
												</Tooltip>
											</div>
											<div className="col-md-6 col-12 d-flex flex-column align-items-center">
												<div className="d-flex align-items-center w-100 justify-content-start">
													<label className="select_lista text-left">
														{t("empresa.datosSede.pais")}{" "}
													</label>{" "}
												</div>
												<Tooltip
													title={!!errores.pais && <span>{errores.pais}</span>}
													open={tooltipVisible.pais}
													color="#1CA6AF">
													<select
														className="form-select form-select-sm w-100"
														id="pais"
														onChange={handleChange}
														value={tempDatosSede.pais || ""}>
														<option value="">
															{t("empresa.datosSede.elejirOpcion")}
														</option>
														{opcionesPaises.map((option) => (
															<option key={option.id} value={option.id}>
																{option.paisnombre}
															</option>
														))}
													</select>
												</Tooltip>
											</div>
											<div className="col-md-6 col-12 d-flex flex-column align-items-center">
												<div className="d-flex align-items-center w-100 justify-content-start">
													<label className="select_lista text-left">
														{t("empresa.datosSede.provincia")}
													</label>{" "}
												</div>
												<Tooltip
													title={
														!!errores.provincia_estado && (
															<span>{errores.provincia_estado}</span>
														)
													}
													open={tooltipVisible.provincia_estado}
													color="#1CA6AF">
													<select
														className="form-select form-select-sm w-100"
														id="provincia_estado"
														onChange={handleChange}
														value={tempDatosSede.provincia_estado || ""}>
														<option value="">
															{t("empresa.datosSede.elejirOpcion")}
														</option>
														{opcionesProvincias.map((option) => (
															<option key={option.id} value={option.id}>
																{option.estadonombre}
															</option>
														))}
													</select>
												</Tooltip>
											</div>
											<div className="col-md-6 col-12 d-flex flex-column align-items-center">
												<div className="d-flex align-items-center w-100 justify-content-start">
													<label className="select_lista">
														{t("empresa.datosSede.ciudad")}
													</label>{" "}
												</div>
												<Tooltip
													title={
														!!errores.ciudad && <span>{errores.ciudad}</span>
													}
													open={tooltipVisible.ciudad}
													color="#1CA6AF">
													<input
														className="form-control w-100"
														type="text"
														placeholder={t("empresa.datosSede.ciudad")}
														id="ciudad"
														value={tempDatosSede.ciudad || ""}
														onChange={handleChange}
													/>
												</Tooltip>
											</div>
											<div className="col-md-6 col-12 d-flex flex-column align-items-center">
												<div className="d-flex align-items-center w-100 justify-content-start">
													<label className="select_lista">
														{t("empresa.datosSede.domicilio")}
													</label>
												</div>
												<Tooltip
													title={
														!!errores.domicilio && (
															<span>{errores.domicilio}</span>
														)
													}
													open={tooltipVisible.domicilio}
													color="#1CA6AF">
													<input
														className="form-control w-100"
														type="text"
														placeholder={t("empresa.datosSede.domicilio")}
														id="domicilio"
														value={tempDatosSede.domicilio || ""}
														onChange={handleChange}
													/>
												</Tooltip>
											</div>
											<div className="col-md-6 col-12 d-flex flex-column align-items-center">
												<div className="d-flex align-items-center w-100 justify-content-start">
													<label className="select_lista">
														{t("empresa.datosSede.telefono")}
													</label>{" "}
												</div>
												<Tooltip
													title={
														!!errores.telefono && (
															<span>{errores.telefono}</span>
														)
													}
													open={tooltipVisible.telefono}
													color="#1CA6AF">
													<input
														className="form-control w-100"
														type="number"
														placeholder={t("tooltip.telefono")}
														id="telefono"
														value={tempDatosSede.telefono || ""}
														onChange={handleChange}
													/>
												</Tooltip>
											</div>
											<div className="col-md-6 col-12 mt-4 d-flex justify-content-center w-100">
												<button className="boton-agregar-empresa" type="submit">
													<span className="label-boton">
														{t("botones.guardarSede")}
													</span>
												</button>
											</div>
										</div>{" "}
									</div>
								</div>
							</form>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</>
	);
};
