import React from "react";
import { Card, Row } from "antd";
import { fNum } from "../funciones_utiles";
import { useTranslation } from "react-i18next";

export const DashboardCard = ({ datoResultado, titulo, alcance }) => {
  const [t] = useTranslation("global");

  // Función para obtener el texto del alcance
  const getAlcanceText = (alcance) => {
    switch (alcance) {
      case "alcance_1":
        return t("panelCalculadoras.alcanceUno"); 
      case "alcance_2":
        return t("panelCalculadoras.alcanceDos"); 
      case "alcance_3":
        return t("panelCalculadoras.alcanceTres");
      default:
        return ""; 
    }
  };

  return (
    <>
      <div className="row justify-content-center pb-3">
        <div className="col-12">
          <Card bordered={true} hoverable={true}>
            <div style={{ marginBottom: "1rem" }}>
         
              <div
                className="alcance-text"
                style={{
                  fontSize: "0.7rem",
                  fontWeight: "700",
                  color: "#4a4a4a",
                }}
              >
                {getAlcanceText(alcance)}
              </div>
            
              <h2
                className="titulos-card-calculadora"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "1000",
                  color: "#1CA6AF",
                }}
              >
                {titulo}
              </h2>
            </div>
            <Row>
              <Row type="flex" align="start" style={{ width: "100%" }}>
                <h4
                  style={{
                    fontSize: "1rem",
                    fontWeight: "900",
                  }}
                >
                  {fNum(datoResultado)}
                </h4>
                <h2
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "900",
                    paddingLeft: "0.4rem",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("tablas.toneladas"),
                    }}
                  />
                </h2>
              </Row>
            </Row>
          </Card>
        </div>
      </div>
    </>
  );
};
