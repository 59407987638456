import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";

export const ChartCompensadasPendientes = ({ data }) => {
  const { t, i18n } = useTranslation("global");
  
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  // Agregar i18n.language como dependencia para actualizar al cambiar el idioma
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const totalEmisiones = data.length;
      const sinCompensar = data.filter((item) => item.estado_num === 1).length;
      const pendientes = data.filter((item) => item.estado_num === 2).length;
      const compensadas = data.filter((item) => item.estado_num === 3).length;

      const porcentajeCompensadas = (compensadas / totalEmisiones) * 100;
      const porcentajePendientes = (pendientes / totalEmisiones) * 100;

      setChartData({
        labels: [t("graficos.texto1"), t("graficos.texto2")],
        datasets: [
          {
            data: [porcentajePendientes, porcentajeCompensadas],
            backgroundColor: ["#508991", "#D79824"],
          },
        ],
      });
    } else {
      console.error("Data is not valid:", data);
    }
  }, [data, i18n.language]);  // Aquí agregamos i18n.language como dependencia

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: t("graficos.titulo1"),
        font: {
          size: 15,
        },
      },
      legend: {
        display: true,
        labels: {
          font: {
            size: 12,
          },
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
  };

  return (
    <div style={{ height: "auto", width: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Doughnut
        options={chartOptions}
        data={chartData}
        style={{
          height: "300px",
          width: "300px",
        }}
      />
    </div>
  );
};
