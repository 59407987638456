import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Tooltip , Button, Menu, Drawer} from "antd";
import withReactContent from "sweetalert2-react-content";
import ReCAPTCHA from "react-google-recaptcha";
import { validarEmail, validarCodigoNumerico } from "../validaciones";
import { LoadMePlease } from "../navegacion/pantalla_carga";
import BackButton from "../navegacion/boton-atras";
import { useTranslation } from "react-i18next";
import { MenuOutlined } from '@ant-design/icons';
// import dotenv  from 'dotenv'
const KEY_CAPTCHA = process.env.REACT_APP_CLAVE_RECAPTCHA;
const URI_RECUPERAR =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/recuperarcontrasenia";
const URI_GENERAR =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/generarcontrasenia";

const MySwal = withReactContent(Swal);
export const CompRecuperar = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("preferredLanguage", lng);
  };
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const [tokenPass, setTokenPass] = useState("");
  const recaptchaRef = useRef(null);
  const [pantallaCargaMail, setPantallaCargaMail] = useState(false);
  const [pantallaCargaCodigo, setPantallaCargaCodigo] = useState(false);

  const [tempDatosCodigo, setTempDatosCodigo] = useState({
    codigo: "",
  });
  const [tempDatosMail, setTempDatosMail] = useState({
    email: "",
  });
  var [tooltipVisible, setTooltipVisible] = useState({});
  const [errores, setErrores] = useState({});
  const [campos, setCampos] = useState([]);
  useEffect(() => {
    let indice;
    tokenPass ? (indice = "codigo") : (indice = "default");
    const camposPorClaseVehiculo = {
      codigo: [{ name: "codigo", validator: validarCodigoNumerico }],
      default: [{ name: "email", validator: validarEmail }],
    };

    const camposAAplicar =
      camposPorClaseVehiculo[indice] || camposPorClaseVehiculo.default;

    setTooltipVisible({
      email: false,
      codigo: false,
    });
    setErrores(
      camposAAplicar.reduce((obj, campo) => ({ ...obj, [campo.name]: "" }), {})
    );
    setCampos(camposAAplicar);
  }, [tokenPass]);
  const handleChange = async (e) => {
    const { id, value } = e.target;
    if (tokenPass) {
      setTempDatosCodigo({
        ...tempDatosCodigo,
        [id]: value,
      });
    } else {
      setTempDatosMail({
        ...tempDatosMail,
        [id]: value,
      });
    }

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);
    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const solicitar = async (e) => {
    e.preventDefault();
    setPantallaCargaMail(true);

    tempDatosMail.token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    let hayErrores = false;
    const erroresTemp = {};

    for (const campo of campos) {
      const valorCampo = String(tempDatosMail[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosMail.lang = currentLanguage;
      const recuperar = await axios.post(URI_RECUPERAR, tempDatosMail);
      if (recuperar.data.errors) {
        setPantallaCargaMail(false);

        const errores = recuperar.data.errors;

        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        // console.log(recuperar.data);
        if (recuperar.data.code === 200) {
          setTokenPass(recuperar.data.codeUrl);
          setTempDatosMail({
            email: "",
          });
          setPantallaCargaMail(false);
        }
      }
    }
  };
  const generar = async (e) => {
    e.preventDefault();
    setPantallaCargaCodigo(true);
    tempDatosCodigo.token = await recaptchaRef.current.executeAsync();
    tempDatosCodigo.tokenPass = tokenPass;

    recaptchaRef.current.reset();
    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCodigo[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, tokenPass);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        // console.log("inside Errores " + campo.name);
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      // console.log(tempDatosCodigo);
      const recuperar = await axios.post(URI_GENERAR, tempDatosCodigo);
      if (recuperar.data.errors) {
        const errores = recuperar.data.errors;
        // 
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        // console.log(recuperar.data);
        if (recuperar.data.code === 200) {
          MySwal.fire({
            html: t("mensajes.mensaje60"),
            imageUrl: "img/img_activada.png",
            imageHeight: 65,
            confirmButtonText: "Ok",
          });
          setTokenPass("");
          setTempDatosCodigo({
            codigo: "",
          });
          setPantallaCargaCodigo(false);

          navigate("/signin");
        }
        if (recuperar.data.code === 403) {
          setPantallaCargaCodigo(false);
          MySwal.fire({
            html: t("mensajes.mensaje61"),
            imageUrl: "img/lechuza_triste.png",
            imageHeight: 65,
            confirmButtonText: "Ok",
          });
          setTempDatosCodigo({
            codigo: "",
          });
        }
      }
    }
  };
  return (
	<div className="regsim body_calculadora">
    <div className="row menu-wrapper justify-content-between align-items-center">
      <div className="col-md-4  col-6 text-left">
        <img
          src="./img/logo-menu.png"
          alt=""
          className="img_logo"
          style={{ width: '10em' }}
        />
      </div>

      <div className="col-md-4 text-center d-none d-md-block">
        <ul className="ul-nuevo mt-2">
          <li className="li-nuevo">
            <a href="https://owl-track.com/" rel="noreferrer" target="_blank">
              {t("botones.inicio")}
            </a>
          </li>
          <li className="li-nuevo">
            <a href="https://owl-track.com/blog/" rel="noreferrer" target="_blank">
              {t("botones.noticias")}
            </a>
          </li>
          <li className="li-nuevo">
            <a href="https://owl-track.com/contacto/" rel="noreferrer" target="_blank">
              {t("botones.contacto")}
            </a>
          </li>
          <li className="li-nuevo">
            <a href="/signup" rel="noreferrer" target="_blank">
              {t("botones.calculadora")}
            </a>
          </li>
        </ul>
      </div>

      <div className="col-md-4 text-right d-none d-md-block">
        <div className="contenendor-idiomas" style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
        <div
              className="bandera"
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => changeLanguage("en")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/en.png"
                alt="English"
                className="bandera"
              />
              <span style={{ fontWeight: "700" }}>EN</span>
            </div>
          <div
              className="bandera "
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => changeLanguage("es")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/esp.png"
                alt="Español"
                className="bandera"
              />
              <span style={{ fontWeight: "700" }}>ES</span>
            </div>
        </div>
      </div>

      <div className="col-6 d-block d-md-none text-right ">
        <Button
        className="btn-burguer-menu"
          type="primary"
          onClick={showDrawer}
          icon={<MenuOutlined />}
        />
      </div>
    </div>

    <Drawer
      title="Owl Track"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
    >
      <Menu mode="vertical">
        <Menu.Item key="1">
          <a href="https://owl-track.com/" rel="noreferrer" target="_blank">
            {t("botones.inicio")}
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href="https://owl-track.com/blog/" rel="noreferrer" target="_blank">
            {t("botones.noticias")}
          </a>
        </Menu.Item>
        <Menu.Item key="3">
          <a href="https://owl-track.com/contacto/" rel="noreferrer" target="_blank">
            {t("botones.contacto")}
          </a>
        </Menu.Item>
        <Menu.Item key="4">
          <a href="/signup" rel="noreferrer" target="_blank">
            {t("botones.calculadora")}
          </a>
        </Menu.Item>
        <Menu.Item key="5">
          <div
            className="bandera"
            style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => changeLanguage('es')}
          >
            <img style={{ marginRight: '0.5em' }} src="./img/esp.png" alt="Español" className="bandera" />
            <span style={{ fontWeight: '500' }}>Español</span>
          </div>
        </Menu.Item>
        <Menu.Item key="6">
          <div
            className="bandera"
            style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => changeLanguage('en')}
          >
            <img style={{ marginRight: '0.5em' }} src="./img/eng.png" alt="English" className="bandera" />
            <span style={{ fontWeight: '500' }}>English</span>
          </div>
        </Menu.Item>
      </Menu>
    </Drawer>
      <div className="container-login-nuevo text-center p-0">
        <BackButton />
        <div className="row p-0 m-0">
          <div className="col-reccon-form">
            {" "}
            <br></br>
            <div className="form-container">
              {" "}
              <p className="subtitulo-recuperar-contraseña">
                {" "}
                {tokenPass ? t("recuperar.texto2") : t("recuperar.texto1")}{" "}
              </p>
              {tokenPass ? (
                <form className="form-login" id="RegForm" onSubmit={generar}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={KEY_CAPTCHA}
                    size="invisible"
                  />
                  {pantallaCargaCodigo ? (
                    <LoadMePlease cargando={t("recuperar.mensajeCarga2")} />
                  ) : (
                    <>
                      <Tooltip
                        title={
                          !!errores.codigo && <span>{errores.codigo}</span>
                        }
                        open={tooltipVisible.codigo}
                        color="red"
                      >
                        <input
                          type="number"
                          placeholder={t("recuperar.placeholderCodigo")}
                          id="codigo"
                          className="input-login"
                          onChange={handleChange}
                          value={tempDatosCodigo.codigo || ""}
                          required
                        ></input>
                      </Tooltip>
                      <button
                        type="submit"
                        value="Registrarme"
                        className="btn-login"
                      >
                        {t("recuperar.botonNuevaContra")}
                      </button>
                    </>
                  )}
                </form>
              ) : (
                <form className="form-login" id="RegForm" onSubmit={solicitar}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={KEY_CAPTCHA}
                    size="invisible"
                  />
                  {pantallaCargaMail ? (
                    <LoadMePlease cargando={t("recuperar.mensajeCarga1")} />
                  ) : (
                    <>
                      <Tooltip
                        title={!!errores.email && <span>{errores.email}</span>}
                        open={tooltipVisible.email}
                        color="red"
                      >
                        <input
                          type="email"
                          placeholder={t("recuperar.placeholderEmail")}
                          id="email"
                          className="input-login"
                          onChange={handleChange}
                          value={tempDatosMail.email || ""}
                          required
                        ></input>
                      </Tooltip>
                      <button
                        type="submit"
                        value="Registrarme"
                        className="btn-login"
                      >
                        {t("recuperar.botonRecuperar")}
                      </button>
                    </>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
};

// export default CompRegistroMini;
