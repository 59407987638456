import axios from "axios"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useSesion } from "./navegacion/sesionContexto"
import jwt_decode from "jwt-decode";

const MySwal = withReactContent(Swal)
// axios.interceptors.request.use(
//     config => {
//       config.headers['Authorization'] = `Bearer ${localStorage.getItem('valid_session')}`;
//           return config;
//       },
//       error => {
//           return Promise.reject(error);
//       }
// )
axios.interceptors.request.use(
    request => {
      if (request.url.includes('rapidapi.com')) {
        return request;
      } else {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem('valid_session')}`;
        return request;
      }
    },
    error => {
      return Promise.reject(error);
    }
  );
export const HeadersAuth = {
    withCredentials: true,
    credentials: 'include',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    }
}
export const CheckExpiracion = () => {

    MySwal.fire({
        text: "Su sesión caducó",
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
        showConfirmButton: true,
        allowOutsideClick:false,
        allowEscapeKey:false,
        showCloseButton:false
    }).then((result) => {
        window.location.href = '/signin';
    })
    localStorage.removeItem('valid_session');
       
}
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('valid_session')}`;
export const traerDatos = (url_api) => {
    // console.log(url_api)
    return new Promise((res, rej) => {
        axios.get(url_api,{
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }})
            .then(response => {
                if(response.data.code === 409){
                    CheckExpiracion();
                }else{
                    // console.log(response)
                    res(response.data)

                }

            })
            .catch(error => console.error(error));
    });
}
export const traerDatosObjeto = (url_api) => {
    return new Promise((res, rej) => {
        axios.get(url_api,{
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }})
            .then(response => {
                if(response.data.code === 409){
                    CheckExpiracion();
                }else{
                res(response.data[0])
                }    


            })
            .catch(error => console.error(error));
    });
}



export const enviarObjetosPost = async (URI,objeto) => {
    console.log(objeto)
    const envioPost = await axios.post(URI, objeto,HeadersAuth)
        if(envioPost.data.errors){

            const errores = envioPost.data.errors

            const msgError = errores.map(
                error => {
                    return '<pre>' + error.msg  + '</pre>'
                }
            )
            MySwal.fire({
                html: `${msgError}`,
                imageUrl: "img/lechuza_triste.png",
                imageHeight: 65,
                confirmButtonText: "Ok",
            })
        }else{
            console.log(envioPost)
            if(envioPost.data.code === 409){
                CheckExpiracion();
            }else if(envioPost.data.code === 200){
                // console.log("im IN")
                return true;
            }else if(envioPost.data.code === 601){
                MySwal.fire({
                    imageUrl: "img/lechuza_triste.png",
                    imageHeight: 65,
                    showConfirmButton: false,
                    timer: 2000,
                    allowOutsideClick:false,
                    allowEscapeKey:false,
                    showCloseButton:false
                })
            }
                
        }
}

export const renewSession = async () => {
  // console.log("ME EJECUTE")
    const URI_RENEW = process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + '/renew_session';
    // const { setSesion } = useSesion();
    try {
      const response = await axios.get(URI_RENEW, {
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      });
      if (response.data.code === 409) {
        CheckExpiracion();
        // console.log("ACA")
      } else {
      //  console.log("renuevo")
        localStorage.setItem('valid_session', response.data.token);
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };

export const fNum = (numero) => {
    const numeroNum = parseFloat(numero)
    const opciones = { 
        style: 'decimal', 
        useGrouping: true, 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 5 
    };
      
    const numeroFormateado = numeroNum.toLocaleString('es-ES', opciones);
    return numeroFormateado
}


export const cambioValor = (valor) => {
  // Si el valor está vacío o solo contiene ceros, devuelve "0,00"
  if (!valor || valor === "0") {
    return "0,00";
  }

  // Elimina cualquier carácter no numérico
  valor = valor.toString().replace(/\D/g, "");

  // Elimina ceros a la izquierda
  valor = valor.replace(/^0+/, "");

  // Limita el número de caracteres a 25
  if (valor.length > 25) {
    valor = valor.slice(0, 25);
  }

  // Formatea el número
  valor = valor
    .replace(/([0-9])([0-9]{2})$/, "$1,$2")
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

  return valor;
};

export const sacaComas = async (stringValue) => {
	stringValue = stringValue.trim();
	var result = stringValue.replace(/[^0-9]/g, "");
	// if (/[,\.]\d{2}$/.test(stringValue)) {
	if (/[,]\d{2}$/.test(stringValue)) {
		result = result.replace(/(\d{2})$/, ".$1");
	}
	return parseFloat(result);
};