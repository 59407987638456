import { BtnAtras } from "../navegacion/btn_atras";
import { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { HeadersAuth, CheckExpiracion } from "../funciones_utiles";
import axios from "axios";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);
const URI_CAMBIAR_PASS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/cambio_cred";

export const CompPassUsuario = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [datosPassword, setDatosPassword] = useState({
    pass: "",
    passb: "",
    passVieja: "",
  });
  // console.log(opcionesPaises)
  const handleChangePass = (e) => {
    const { id, value } = e.target;
    setDatosPassword({
      ...datosPassword,
      [id]: value,
    });
  };
  const cambiar = async (e) => {
    e.preventDefault();
    const cambio = await axios.post(
      URI_CAMBIAR_PASS,
      datosPassword,
      HeadersAuth
    );
    if (cambio.data.errors) {
      console.log(cambio.data);
      const errores = cambio.data.errors;
      
      const msgError = errores.map((error) => {
        return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
      });
      MySwal.fire({
        html: `${msgError}`,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      if (cambio.data.code === 409) {
        CheckExpiracion();
      } else {
        if (cambio.data.code === 200) {
          MySwal.fire({
            text:  t("mensajes.mensaje59"),
            icon: "success",
            confirmButtonText: "Ok",
          });
          setDatosPassword({
            pass: "",
            passb: "",
            passVieja: "",
          });
        }
      }
    }
  };

  return (
    <>
      {/* <h1 className='nombre-perfil'>Perfil{console.log("CUERPO "+HeadersAuth)}</h1> */}
      <div className="row g-3 justify-content-center align-items-center ms-4 me-4 cambio_contraseña mt-5">
        <div className=" col-12 col-md-4 mt-2 ">
          <form onSubmit={cambiar}>
            <div className="row g-3 justify-content-center align-items-center ms-4 me-4">
              <div className="contenedor-contraseña col-12 col-md-12">
                <div className="password-input">
                  <label className="select_lista" htmlFor="name">
                  {t("contraseña.contraseñaActual")}
                  </label>
                  <input
                    className="form-control  w-100"
                    type={showPassword ? "text" : "password"}
                    id="passVieja"
                    value={datosPassword.passVieja || ""}
                    onChange={handleChangePass}
                    required
                  />
                  <span
                    className="password-toggle-contraseña"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEye onClick={togglePasswordVisibility} />
                    ) : (
                      <FaEyeSlash onClick={togglePasswordVisibility} />
                    )}
                  </span>
                </div>
              </div>
              <div className="contenedor-contraseña col-12 col-md-12">
                <div className="password-input">
                  <label className="select_lista" htmlFor="name">
                  {t("contraseña.contraseñaNueva")}
                  </label>
                  <input
                    className="form-control w-100"
                    type={showPassword ? "text" : "password"}
                    id="pass"
                    value={datosPassword.pass || ""}
                    onChange={handleChangePass}
                    required
                  />
                  <span
                    className="password-toggle-contraseña"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEye onClick={togglePasswordVisibility} />
                    ) : (
                      <FaEyeSlash onClick={togglePasswordVisibility} />
                    )}
                  </span>
                </div>
              </div>
              <div className="contenedor-contraseña col-md-12">
                <div className="password-input">
                  <label className="select_lista" htmlFor="name">
                  {t("contraseña.repetirContraseña")}
                  </label>
                  <input
                    className="form-control   w-100"
                    type={showPassword ? "text" : "password"}
                    id="passb"
                    value={datosPassword.passb || ""}
                    onChange={handleChangePass}
                    required
                  />
                  <span
                    className="password-toggle-contraseña"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEye onClick={togglePasswordVisibility} />
                    ) : (
                      <FaEyeSlash onClick={togglePasswordVisibility} />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12 d-flex flex-column align-items-center gap-1">
              {" "}
              <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
            <BtnPrincipal name={t("botones.guardar")} />
            </div></div>
          </form>
        </div>
      </div>
    </>
  );
};
