import { BtnAtras } from "../navegacion/btn_atras";
import { Card, List } from "antd";
import axios from "axios";
import "moment/locale/es";
import { Link } from "react-router-dom";
import { DashboardCard } from "../navegacion/DashboardCard";
import { DashboardCardMain } from "../navegacion/DashboardCardMain";
import { ChartEmisiones } from "../charts/ChartEmisionesReporte";
import { ChartEmisionesMultiMeses } from "../charts/ChartEmisionesMultiMeses";
import { ChartPie } from "../charts/ChartPieDefault";
import { ChartBarras } from "../charts/ChartBarrasVertical";
import { NotFound404 } from "../navegacion/404_not_found";
import { HeadersAuth } from "../funciones_utiles";
import { CheckExpiracion } from "../funciones_utiles";
import { Tooltip, Modal } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React, { useState, useEffect } from "react";
import { LoadMePlease } from "../navegacion/pantalla_carga";
import { useSesion } from "../navegacion/sesionContexto";
import { EyeOutlined } from "@ant-design/icons";
import { FaQuestionCircle, FaEye, FaLeaf } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

const MemoizedChartAnios = React.memo(ChartEmisionesMultiMeses);

export const CompPanelReportes = () => {
	const [t, i18n] = useTranslation("global");
	const currentLanguage = i18n.language;
	const { sesion } = useSesion();
	const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
	const [idSede, setIdSede] = useState(sesion.sedeActiva);
	const plan = sesion.datosPlanes.find(
		(item) => parseInt(item.id_empresa) === parseInt(sesion.empresaActiva)
	);

	const URI_DATOS_REPORTE =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/datos_reporte";
	const URI_DATOS_REPORTE_MACRO =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/datos_reporte_macro";
	const URI_PROYECTOS =
		process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/proyectos";
	var [loaderBtn, setLoaderBtn] = useState(false);
	var [year, setYear] = useState("");
	const [dataReportes, setDataReportes] = useState({});
	const [dataChart, setDataChart] = useState([]);
	const [trigger, setTrigger] = useState(false);
	const [hayDatos, setHayDatos] = useState(true);
	const [optionsYears, setOptionYears] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const handleStart = () => setIsLoading(true);
		const handleComplete = () => setIsLoading(false);
		let timeout;
		handleStart();

		// Verifica si 'datos_reportes_mes' tiene datos
		if (year && dataReportes?.datos_reportes_mes?.length > 0) {
			// handleComplete();
			timeout = setTimeout(handleComplete, 500); // Simula un tiempo de carga
		}
		return () => clearTimeout(timeout);
	}, [year, dataReportes]);
	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const [isModalOpen2, setIsModalOpen2] = useState(false);

	const showModal2 = () => {
		setIsModalOpen2(true);
	};

	const handleOk2 = () => {
		setIsModalOpen2(false);
	};

	const handleCancel2 = () => {
		setIsModalOpen2(false);
	};
	const [isModalOpen3, setIsModalOpen3] = useState(false);
	const handleOk3 = () => {
		setIsModalOpen3(false);
	};

	const handleCancel3 = () => {
		setIsModalOpen3(false);
	};
	// const [seccion, setSeccion] = useState("");
	const handleChangeAnio = (anio) => {
		// const { value } = e.currentTarget.target;
		// console.log(value);
		setYear(anio);
	};
	useEffect(() => {
		(async () => {
			const datosMacro = await axios.post(
				URI_DATOS_REPORTE_MACRO,
				{
					id: idEmpresa,
					id_sede: idSede,
				},
				HeadersAuth
			);
			// console.log("HAY: "+ datosMacro.data.mes_anio.length)
			if (datosMacro.data.emisiones_anio.length > 0) {
				setHayDatos(true);
			} else {
				setHayDatos(false);
			}
			setOptionYears(datosMacro.data.emisiones_anio);
			setDataChart(datosMacro.data.mes_anio);
		})();
	}, [idEmpresa, URI_DATOS_REPORTE_MACRO, idSede]);
	useEffect(() => {
		(async () => {
			const reportes = await axios.post(
				URI_DATOS_REPORTE,
				{
					anio: year,
					id: idEmpresa,
					id_sede: idSede,
				},
				HeadersAuth
			);

			setDataReportes(reportes.data);
		})();
	}, [year, idEmpresa, trigger, URI_DATOS_REPORTE, URI_PROYECTOS, idSede]);
	const [activeTabKey2, setActiveTabKey2] = useState();
	const [listaTab, setListaTab] = useState([]);
	const [contenidoTab, setContenidoTab] = useState({});

	const onTab2Change = (key) => {
		setActiveTabKey2(key);
	};
	useEffect(() => {
		setIdEmpresa(sesion.empresaActiva);
		setIdSede(sesion.sedeActiva);

		const listaTab = [];
		const contenidoTab = {};

		if (dataReportes.datos_reportes) {
			dataReportes.datos_reportes.forEach((reporte) => {
				const nombre = reporte.nombre;
				const nombre_en = reporte.nombre_en;
				const datos = reporte.datos;

				if (datos && datos.length > 0) {
					const key = `grafico_${nombre.toLowerCase().replace(/\s/g, "_")}`;

					listaTab.push({
						key: key,
						tab: (
							<b className="subtitulo-grafico">
								{currentLanguage === "es" ? nombre : nombre_en}{" "}
								<img
									src="./img/img_activada.png"
									alt=""
									className="img-fluid img-tabla"
								/>
							</b>
						),
						disabled: false,
					});

					contenidoTab[key] = <ChartEmisiones data={datos} year={year} />;
				}
			});
		}
		setActiveTabKey2(listaTab.length > 0 ? listaTab[0].key : "");
		// console.log(listaTab.length > 0 ? listaTab[0].key : "NO EXISTO")
		setListaTab(listaTab);
		setContenidoTab(contenidoTab);
	}, [dataReportes, year, sesion.empresaActiva, sesion.sedeActiva]);
	const listaTabFiltrada = listaTab.filter((tab) => !tab.disabled);
	const alcancesArrayLabels = [
		t("panelCalculadoras.alcanceUno"),
		t("panelCalculadoras.alcanceDos"),
		t("panelCalculadoras.alcanceTres"),
	];
	const alcancesEtiqueta = t("reportes.seccionEstadisticas.alcances");
	const alcancesArrayData = [
		dataReportes.total_alcance_uno,
		dataReportes.total_alcance_dos,
		dataReportes.total_alcance_tres,
	];
	const alcancesArrayBackground = ["#D79824", "#508991", "#176585"];
	const alcancesArrayBorder = ["#ffffff", "#ffffff"];
	const alcancesTitulo = t("reportes.seccionEstadisticas.alcances");
	const emisionesArrayData = [
		dataReportes.total_alcance_uno,
		dataReportes.total_alcance_dos + dataReportes.total_alcance_tres,
	];
	const emisionesArrayLabels = [
		t("reportes.seccionEstadisticas.emisionesDirectas"),
		t("reportes.seccionEstadisticas.emisionesIndirectas"),
	];
	const emisionesArrayBackground = ["#D79824", "#508991"];
	const emisionesArrayBorder = ["#ffffff", "#ffffff"];

	const generarPDF = (event, id, anio, id_sede) => {
		event.preventDefault();
		if (!sesion.checkPerfil || !sesion.checkSede) {
			let mensajePerfil;
			let mensajeSede;
			if (sesion.checkPerfil) {
				mensajePerfil = "";
			} else {
				mensajePerfil = t("mensajes.mensaje74");
			}
			if (sesion.checkSede) {
				mensajeSede = "";
			} else {
				if (sesion.duenio) {
					mensajeSede = t("mensajes.mensaje75");
				} else {
					mensajeSede = t("mensajes.mensaje76");
				}
			}

			MySwal.fire({
				html: `
                ${mensajePerfil}</br>${mensajeSede}
                
                `,
				icon: "warning",
				confirmButtonText: "Ok",
			});
		} else {
			const URI_PDF_RESULTADO =
				process.env.REACT_APP_DOMINIO +
				process.env.REACT_APP_PUERTO +
				plan.reporte_premium;
			// var loading = false
			setLoaderBtn(true);
			axios
				.post(
					URI_PDF_RESULTADO,
					{ id: id, id_sede: id_sede, anio: anio, lang: currentLanguage },
					{
						withCredentials: true,
						credentials: "include",
						headers: {
							"Access-Control-Allow-Origin": "*",
							"Content-Type": "application/json",
							Accept: "application/pdf",
						},
					}
				)
				.then((response) => {
					if (parseInt(response.data.code) === 200) {
						const currentDate = new Date();
						const year = currentDate.getFullYear();
						const month = String(currentDate.getMonth() + 1).padStart(2, "0");
						const day = String(currentDate.getDate()).padStart(2, "0");
						const hours = String(currentDate.getHours()).padStart(2, "0");
						const minutes = String(currentDate.getMinutes()).padStart(2, "0");
						const seconds = String(currentDate.getSeconds()).padStart(2, "0");
						const milliseconds = String(currentDate.getMilliseconds()).padStart(
							3,
							"0"
						);

						const uniqueHash = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
						const nombreHash = Math.imul(31, id);
						// console.log(url)
						setLoaderBtn(false);

						// const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.data).buffer]));
						const link = document.createElement("a");
						link.href =
							"data:application/octet-stream;base64," + response.data.pdf;
						link.setAttribute(
							"download",
							"reporte_owltrack_" + uniqueHash + nombreHash + ".pdf"
						);
						document.body.appendChild(link);
						link.click();
						trigger ? setTrigger(false) : setTrigger(true);
					} else if (
						parseInt(response.data.code) === 401 &&
						parseInt(response.data.code) === 409 &&
						parseInt(response.data.code) === 601
					) {
						MySwal.fire({
							text: t("mensajes.mensaje12"),
							imageUrl: "img/lechuza-triste.png",
							imageHeight: 55,
							confirmButtonText: "Ok",
						});
						setLoaderBtn(false);
					} else if (parseInt(response.data.code) === 400) {
						MySwal.fire({
							text: t("mensajes.mensaje77"),
							imageUrl: "img/lechuza-triste.png",
							imageHeight: 55,
							confirmButtonText: "Ok",
						});
						setLoaderBtn(false);
					}
				})
				.catch((error) => console.log(error));
		}
	};

	const REgenerarPDF = (e) => {
		e.preventDefault();
		if (!sesion.checkPerfil || !sesion.checkSede) {
			let mensajePerfil;
			let mensajeSede;
			if (sesion.checkPerfil) {
				mensajePerfil = "";
			} else {
				mensajePerfil = t("mensajes.mensaje74");
			}
			if (sesion.checkSede) {
				mensajeSede = "";
			} else {
				if (sesion.duenio) {
					mensajeSede = t("mensajes.mensaje75");
				} else {
					mensajeSede = t("mensajes.mensaje76");
				}
			}

			MySwal.fire({
				html: `
                ${mensajePerfil}</br>${mensajeSede}
                
                `,
				icon: "warning",
				confirmButtonText: "Ok",
			});
		} else {
			const { value } = e.target;
			const URI_REGENERAR_PDF =
				process.env.REACT_APP_DOMINIO +
				process.env.REACT_APP_PUERTO +
				"/regenerar_pdf?id=" +
				idEmpresa +
				"&ids=" +
				idSede +
				"&idp=" +
				value;
			// var loading = false
			setLoaderBtn(true);
			axios
				.get(URI_REGENERAR_PDF, {
					withCredentials: true,
					credentials: "include",
					headers: {
						"Access-Control-Allow-Origin": "*",
						"Content-Type": "application/json",
						Accept: "application/pdf",
					},
				})
				.then((response) => {
					// console.log(response.data.pdf)
					if (parseInt(response.data.code) === 200) {
						const nombreHash = Math.imul(31, value);
						// console.log(url)
						setLoaderBtn(false);

						// const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.data).buffer]));
						const link = document.createElement("a");
						link.href =
							"data:application/octet-stream;base64," + response.data.pdf;
						link.setAttribute(
							"download",
							"reporte_" + year + "_" + nombreHash + ".pdf"
						);
						document.body.appendChild(link);
						link.click();
						trigger ? setTrigger(false) : setTrigger(true);
					} else if (
						parseInt(response.data.code) === 401 &&
						parseInt(response.data.code) === 409 &&
						parseInt(response.data.code) === 601
					) {
						MySwal.fire({
							text: t("mensajes.mensaje12"),
							imageUrl: "img/lechuza-triste.png",
							imageHeight: 55,
							confirmButtonText: "Ok",
						});
						setLoaderBtn(false);
					}
				})
				.catch((error) => console.log(error));
		}
	};

	const borrar = async (e, value) => {
		e.preventDefault();
		window.scrollTo(0, 0);
		const URI_BORRAR_PDFS =
			process.env.REACT_APP_DOMINIO +
			process.env.REACT_APP_PUERTO +
			"/borrar_pdf?id=" +
			idEmpresa +
			"&ids=" +
			idSede +
			"&idp=" +
			value;
		const borrar = await axios.delete(URI_BORRAR_PDFS, HeadersAuth);
		if (borrar.data.code === 401 || borrar.data.code === 402) {
			MySwal.fire({
				text: t("mensajes.mensaje12"),
				imageUrl: "img/lechuza-triste.png",
				imageHeight: 55,
				confirmButtonText: "Ok",
			});
		} else {
			if (borrar.data.code === 409) {
				CheckExpiracion();
			} else {
				if (borrar.data.code === 200) {
					MySwal.fire({
						text: t("mensajes.mensaje10"),
						imageUrl: "img/borrar.png",
						imageHeight: 50,
						confirmButtonText: "Ok",
					});
					trigger ? setTrigger(false) : setTrigger(true);
				}
			}
		}
	};

	return (
		<>
			{year === "" ? (
				!hayDatos ? (
					<BtnAtras titulo={"Upss!"} />
				) : (
					<BtnAtras titulo={t("reportes.seccionReportes.reportes")} />
				)
			) : (
				<BtnAtras titulo={`${t("reportes.general.titulo")} ${year}`} />
			)}
			{plan ? (
				hayDatos ? (
					// optionsYears.length > 0 ?
					<div className="perfil row g-3 px-3 d-flex justify-content-center align-items-center  mt-2 panel-reporte mb-5">
						<div className="row justify-content-center">
							{year === "" ? (
								<>
									<div className="col-md-5 col-input-calculadoras">
										<Card
											bordered={true}
											hoverable={true}
											style={{ height: "24em", marginBottom: "1em" }}>
											<h2
												style={{
													fontSize: "1rem",
													fontWeight: "1000",
													color: "#1CA6AF",
												}}>
												{t("reportes.general.elejirAño")}
											</h2>
											<List
												pagination={{
													size: "small",
													pageSize: 3,
													position: "top",
													align: "end",
												}}
												itemLayout="horizontal"
												dataSource={optionsYears}
												renderItem={(item) => (
													<List.Item
														actions={[
															<Tooltip
																placement="left"
																title={t("reportes.general.verReporte")}
																className="tooltip-head">
																<button
																	style={{ border: "0", background: "none" }}
																	onClick={() => handleChangeAnio(item.anio)}>
																	<EyeOutlined />
																</button>
															</Tooltip>,
														]}>
														<List.Item.Meta
															title={
																<b style={{ fontSize: "1.2rem" }}>
																	{item.anio}
																</b>
															}
															description={
																<b>
																	{parseFloat(item.total_codose).toFixed(2) +
																		" tCO"}
																	<sub>2eq</sub> {t("reportes.general.emitido")}
																</b>
															}
														/>
													</List.Item>
												)}
											/>
										</Card>
									</div>
									<div className="col-md-7 col-input-calculadoras">
										<Card bordered={true} hoverable={true}>
											<div>
												<MemoizedChartAnios data={dataChart} />
											</div>
										</Card>
									</div>
								</>
							) : !isLoading ? (
								<>
									<div className="col-md-12 ">
										<div className="row justify-content-start pb-3">
											<div className="col-md-12 col-ms-12 d-flex flex-row justify-content-between align-items-center">
												<div
													style={{
														display: "flex",
														justifyContent: "flex-end",
													}}>
													<button
														className="btnprincipal p-1"
														onClick={() => setYear("")}
														style={{
															margin: "0",
															width: "9em",
															height: "2.5em",
														}}>
														{t("reportes.general.cambiarAño")}
													</button>
												</div>
											</div>
										</div>

										<Modal
											className="custom-modal"
											title={t("reportes.seccionEstadisticas.estadisticas")}
											open={isModalOpen}
											onOk={handleOk}
											onCancel={handleCancel}
											okText={t("botones.aceptar")}
											cancelText={t("botones.cancelar")}
											cancelButtonProps={{ className: "custom-cancel-button" }}>
											<div className="row align-items-center">
												<div className="col-md-6">
													<img
														alt=""
														src="../img/ayuda1.png"
														className="img-ayuda"></img>
												</div>
												<div className="col-md-6 ">
													<p>
														{t("reportes.seccionEstadisticas.ayuda.ayuda1")}
													</p>
												</div>
											</div>
											<hr></hr>
											<div className="row">
												<div className="col-md-6">
													<img
														alt=""
														src="../img/ayuda2.png"
														className="img-ayuda"></img>
												</div>
												<div className="col-md-6 ">
													<p>
														{t("reportes.seccionEstadisticas.ayuda.ayuda2")}
													</p>
												</div>
											</div>
											<hr></hr>
											<div className="row">
												<div className="col-md-6">
													<img
														alt=""
														src="../img/ayuda3.png"
														className="img-ayuda"></img>
												</div>
												<div className="col-md-6 ">
													<p>
														{" "}
														{t("reportes.seccionEstadisticas.ayuda.ayuda3")}
													</p>
												</div>
											</div>
										</Modal>
										<section id="estadisticas" className="section">
											<div className="row justify-content-between ">
												<div className="col col-xl-4 col-md-12 ">
													<DashboardCardMain
														titulo={
															<>
																{t("reportes.seccionEstadisticas.totalEmitido")}
																<Tooltip
																	title={t("tooltip.ayuda")}
																	placement="top">
																	<FaQuestionCircle
																		style={{
																			marginLeft: "5px",
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1em",
																			marginBottom: "5px",
																		}}
																		onClick={showModal}
																	/>
																</Tooltip>
															</>
														}
														datoResultado={dataReportes.total_general}
														directas={dataReportes.total_porcentaje_directas}
														indirectas={
															dataReportes.total_porcentaje_indirectas
														}
														style={{ paddingBottom: "2em" }}
													/>
												</div>
												<div className="col col-xl-4 col-md-12 mb-2">
													<Card bordered={true} hoverable={true}>
														<h2
															style={{
																fontSize: "1rem",
																fontWeight: "600",
																color: "#1CA6AF",
															}}>
															{t("reportes.seccionEstadisticas.alcances")}
														</h2>
														<span>
															<div>
																<ChartPie
																	ArrayLabels={alcancesArrayLabels}
																	Etiqueta={alcancesEtiqueta}
																	ArrayData={alcancesArrayData}
																	ArrayBackground={alcancesArrayBackground}
																	ArrayBorder={alcancesArrayBorder}
																	Titulo={alcancesTitulo}
																/>
															</div>
														</span>
													</Card>
												</div>
												<div className="col col-xl-4 col-md-12  mb-2 ">
													<Card bordered={true} hoverable={true}>
														<h2
															style={{
																fontSize: "1rem",
																fontWeight: "600",
																color: "#1CA6AF",
															}}>
															{t("reportes.seccionEstadisticas.tipoEmisiones")}
														</h2>
														<span>
															<div>
																<ChartBarras
																	ArrayLabels={emisionesArrayLabels}
																	ArrayData={emisionesArrayData}
																	ArrayBackground={emisionesArrayBackground}
																	ArrayBorder={emisionesArrayBorder}
																/>
															</div>
														</span>
													</Card>
												</div>

												<div
													className="row justify-content-center"
													style={{ marginLeft: "0.1em" }}>
													{!dataReportes || !dataReportes.datos_calculadoras ? (
														<div>
															{t("reportes.seccionEstadisticas.cargandoDatos")}
														</div>
													) : (
														Object.values(dataReportes.datos_calculadoras)
                            .sort((a, b) => {
                              // Extraer el número de alcance ("alcance_1" => 1)
                              const alcanceA = parseInt(a.alcance.split('_')[1], 10);
                              const alcanceB = parseInt(b.alcance.split('_')[1], 10);
                              // Ordenar por alcance primero y luego por total
                              if (alcanceA === alcanceB) {
                                return b.total - a.total; // Si el alcance es igual, ordenar por total
                              }
                              return alcanceA - alcanceB; // Ordenar por alcance de menor a mayor
                            })
															.map((calculadora, index) =>
																calculadora.total !== 0 ? (
																	<div
																		className="col-md-4 col-6"
																		key={`${calculadora.codigo}-${index}`}>
																		<DashboardCard
																			titulo={`${
																				currentLanguage === "es"
																					? calculadora.nombre
																					: calculadora.nombre_en
																			}`}
																			datoResultado={parseFloat(
																				calculadora.total
																			).toFixed(2)}
																			alcance={calculadora.alcance} // Pasar el alcance como prop
																		/>
																	</div>
																) : null
															)
													)}
												</div>
											</div>

											<div className="row justify-content-start pb-3">
												<div className="col-md-9">
													<Card
														bordered={true}
														hoverable={true}
														tabList={listaTabFiltrada}
														activeTabKey={activeTabKey2}
														tabBarExtraContent={
															<Link to={"/calculadoras"}>
																{t(
																	"reportes.seccionEstadisticas.irCalculadora"
																)}
															</Link>
														}
														onTabChange={onTab2Change}
														className="tab-reportes">
														{contenidoTab[activeTabKey2]}
													</Card>
												</div>
												<div className="col-md-3 contenedor-reporte ">
													<Card
														bordered={true}
														hoverable={true}
														style={{ height: "100%" }}>
														<div className="row row-usuarios">
															<h2
																style={{
																	fontSize: "1rem",
																	fontWeight: "600",
																	color: "#1CA6AF",
																}}>
																{t("reportes.seccionReportes.reportes")} {year}
																<Tooltip
																	title={t("tooltip.ayuda")}
																	placement="top">
																	<FaQuestionCircle
																		style={{
																			marginLeft: "5px",
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1em",
																			marginBottom: "5px",
																		}}
																		onClick={showModal2}
																	/>
																</Tooltip>
															</h2>
															{/* <div className="col-md-2"> 
                                  <Table
																		rowKey="id"
																		dataSource={dataPdfs}
																		columns={ColumnsPdfs}
																		scroll={elScroll}
																		pagination={{
																			position: ["bottomRight"],
																			pageSize: 3,
																		}}
																		size="small"
																	/> 
                                </div>*/}
															<div
																className="col-md-12 justify-content-center align-items-center text-center "
																id="nuevo-reporte">
																{loaderBtn ? (
																	<>
																		<div className="justify-content-center align-items-center text-center ">
																			<Card
																				className="card-con-cursor text-center"
																				style={{
																					border: "none",
																					fontSize: "1rem",
																					fontWeight: "600",
																					color: "#1CA6AF",
																				}}>
																				<h2
																					style={{
																						fontSize: "1rem",
																						fontWeight: "600",
																						color: "#1CA6AF",
																					}}>
																					{t(
																						"reportes.seccionReportes.generandoReporte"
																					)}
																				</h2>

																				<img
																					src="img/lechuza-reporte.png"
																					alt="Lechuza OWLTRACK Reporte"
																					style={{ width: "7em" }}
																					className="img-reportes"></img>
																			</Card>{" "}
																		</div>
																	</>
																) : (
																	<>
																		<div className="justify-content-center align-items-center text-center">
																			<Card
																				className="card-con-cursor text-center "
																				onClick={(event) =>
																					generarPDF(
																						event,
																						idEmpresa,
																						year,
																						idSede
																					)
																				}
																				style={{
																					fontSize: "1rem",
																					fontWeight: "600",
																					color: "#1CA6AF",
																				}}>
																				<h2
																					style={{
																						fontSize: "1rem",
																						fontWeight: "600",
																						color: "#1CA6AF",
																					}}>
																					{t(
																						"reportes.seccionReportes.nuevoReporte"
																					)}
																				</h2>

																				<img
																					src="img/lechuza-reporte.png"
																					alt="Lechuza OWLTRACK reporte"
																					style={{ width: "7em" }}
																					className="img-reportes"></img>
																			</Card>
																		</div>
																	</>
																)}
															</div>
														</div>
													</Card>
												</div>
											</div>
										</section>

										<Modal
											className="custom-modal"
											title={t("reportes.seccionReportes.reportes")}
											open={isModalOpen2}
											onOk={handleOk2}
											onCancel={handleCancel2}
											okText={t("botones.aceptar")}
											cancelText={t("botones.cancelar")}
											cancelButtonProps={{ className: "custom-cancel-button" }}>
											<div className="row">
												<div className="col-md-6">
													<img
														alt=""
														style={{ width: "50%" }}
														src="../img/ayuda4.png"
														className="img-ayuda"></img>
												</div>
												<div className="col-md-6">
													<p style={{ marginLeft: "1em", marginTop: "3em" }}>
														{t("reportes.seccionReportes.ayuda.ayuda1")}
													</p>
												</div>
											</div>
										</Modal>

										<Modal
											className="custom-modal"
											title={t("compensacion.general.compensacion")}
											open={isModalOpen3}
											onOk={handleOk3}
											onCancel={handleCancel3}
											okText={t("botones.aceptar")}
											cancelText={t("botones.cancelar")}
											cancelButtonProps={{ className: "custom-cancel-button" }}>
											<div className="row">
												<div className="col-md-6">
													<p style={{ marginLeft: "1em" }}>
														{" "}
														1 - {t("compensacion.ayuda.ayuda1")}
													</p>

													<img
														alt=""
														src="../img/ayuda5.png"
														className="img-ayuda"></img>
												</div>
												<div className="col-md-6">
													<p style={{ marginLeft: "1em" }}>
														2 - {t("compensacion.ayuda.ayuda2parte1")}
														<FaEye className="mx-2" />
														{t("compensacion.ayuda.ayuda2parte2")}
														<FaLeaf className="mx-2" />
														{t("compensacion.ayuda.ayuda2parte3")}
													</p>
													<img
														alt=""
														src="../img/ayuda6.png"
														className="img-ayuda"></img>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<p style={{ marginLeft: "1em" }}>
														{" "}
														3 - {t("compensacion.ayuda.ayuda3")}
													</p>

													<img
														alt=""
														src="../img/ayuda7.png"
														className="img-ayuda"></img>
												</div>
												<div className="col-md-6">
													<p style={{ marginLeft: "1em" }}>
														{" "}
														4 - {t("compensacion.ayuda.ayuda4")}
													</p>

													<img
														alt=""
														src="../img/ayuda8.png"
														className="img-ayuda"></img>
												</div>
											</div>
										</Modal>
									</div>
								</>
							) : (
								<LoadMePlease cargando={t("mensajes.mensaje69")} />
							)}
						</div>
					</div>
				) : (
					<>
						<div className="perfil row pb-3 g-3 justify-content-center ms-4 me-4">
							<img
								alt=""
								className="img-lechuza-triste"
								src="./img/lechuza_triste.png"
							/>
						</div>
						<div className="perfil row g-3 justify-content-center ms-4 me-4">
							<h2
								className="d-flex justify-content-center"
								style={{
									fontSize: "2rem",
									fontWeight: "1000",
									color: "#1CA6AF",
								}}>
								Usted no ha realizado ninguna medición aún!
							</h2>
							<Link
								to={"/calculadoras"}
								className="d-flex justify-content-center"
								style={{
									fontSize: "1.5rem",
									fontWeight: "1000",
									color: "#1CA6AF",
								}}>
								Ir a calcular
							</Link>
						</div>
					</>
				)
			) : (
				<NotFound404 />
			)}
		</>
	);
};
