import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useTranslation } from "react-i18next";
export const ChartEmisionesCompensadasPorProyecto = ({ data }) => {
  const { t, i18n } = useTranslation("global");
  const currentLanguage = i18n.language;
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });

  const colores = [
    '#508991', 
    '#D79824', 
    '#172A3A', 
    '#004346', 
    '#09BC8A', 
    'rgba(255, 159, 64, 0.6)', 
  ];

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const proyectos = {};

      data.forEach((item) => {
        const proyectoNombre = item.nombre_proyecto;
        const emisiones = item.cantidad;

        if (item.estado_num === 3) {
          if (!proyectos[proyectoNombre]) {
            proyectos[proyectoNombre] = 0;
          }
          proyectos[proyectoNombre] += emisiones;
        }
      });

      const backgroundColors = Object.keys(proyectos).map((_, index) => colores[index % colores.length]);

      setChartData({
        labels: Object.keys(proyectos),
        datasets: [{
          data: Object.values(proyectos),
          backgroundColor: backgroundColors,
          borderColor: backgroundColors,
          borderWidth: 1,
        }]
      });
    } else {
      console.error('Data is not valid:', data);
    }
  }, [data]);

  const chartOptions = {
    responsive: true, // Mantener el gráfico responsivo
    maintainAspectRatio: false, // Permitir modificar la altura y el ancho
    plugins: {
      title: {
        display: true,
        text: t("graficos.titulo3"),
        font: {
          size: 16
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t("compensacion.informacion.titulo2"),
          font: {
            size: 14
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'tCO2eq',
          font: {
            size: 14
          }
        },
        beginAtZero: true
      }
    }
  };

  return (
    <div style={{ height: "260px", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <Bar 
          data={chartData} 
          options={chartOptions} 
        />
      </div>
    </div>
  );
};
